import React from 'react';
import Layout from '../../Components/Layout/Layout';
import './Giving.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';
/**
 * @namespace Giving
 * @function Giving
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function Giving() {
  const data = useStaticQuery(graphql`
    query givingQuery {
      allSanityGiving {
        nodes {
          _rawBody
          image {
            asset {
              url
            }
          }
          givingLink
        }
      }
    }
  `);
  const link = data?.allSanityGiving?.nodes?.[0]?.givingLink;
  const image = data?.allSanityGiving?.nodes?.[0]?.image;
  const body = data?.allSanityGiving?.nodes?.[0]?._rawBody;
  return (
    <Layout
      className='Giving-Container'
      title='Giving'
      description='Giving is an act of worship, and an acknowledgement that God is our Lord, Savior and Provider.'
    >
      <h1>Giving</h1>
      <div className='Content'>
        <div className='Text'>{body && <PortableText value={body} />}</div>
        <div className='Image'>
          <img
            // src={content && urlFor(content?.image).width(550).height(360).url()}
            src={image?.asset?.url}
            alt='Prayer Group'
            loading='lazy'
          />
        </div>
      </div>
      <div className='Give-Link'>
        <a
          className='btn custom-btn'
          href={link}
          target='_blank'
          rel='noreferrer'
        >
          Give
        </a>
      </div>
    </Layout>
  );
}
